var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "glances-load-wrapper" }, [
    _c("div", {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.$t("widgets.glances.system-load-desc"),
          expression: "$t('widgets.glances.system-load-desc')",
        },
      ],
      staticClass: "load-chart",
      attrs: { id: `load-${_vm.chartId}` },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }